import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "248",
  height: "250",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#EEF5FF",
      d: "M80.374 243.847c17.382 10.476 29.783 6.127 34.53-2.274 9.181-16.248 39.706-49.472 88.081-60.701 64.069-14.873 50.689-54.363 11.303-75.086S145.453 56.39 149.91 25.213c4.457-31.178-39.705-41.926-59.344 18.05-12.692 38.757-35.436 49.14-49.94 51.636-13.669 2.353-28.621 5.304-35.913 17.103-8.847 14.316-5.76 28.798 31.284 38.73 52.494 14.075 5.416 69.63 44.377 93.115"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#3E8CFF",
      d: "M35.212 175.915c.735-.421 9.038-3.936 50.342 15.32-.12.164-.172.241-.172.241s-42.94 14.461-51.353-3.354c-2.512-5.303-1.792-8.955 1.11-12.166zM174.039 183.202c-8.974 7.544-23.046 13.02-36.625 5.739 2.145-1.372 4.362-2.784 6.622-4.245 13.637-8.762 25.649-2.899 30.003-1.494"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#A2BDFF",
      d: "M190.112 90.418c9.168 76.344-12.62 90.2-15.046 92.304-.702.61-.987.565-2.048.292l-.019.01c-4.493-1.191-15.327-7.09-28.964 1.671-2.261 1.461-4.478 2.873-6.623 4.244-13.911 8.823-25.145 15.041-33.239 11.432-6.865-3.502-13.055-6.533-18.62-9.136-41.304-19.256-49.606-15.741-50.342-15.32.854-.465 8.684-4.805 19.227-12.438.036-.02.071-.041.08-.069 14.091-10.213 32.964-26.307 46.529-47.01 9.235-14.078 18.27-49.768 35.701-56.771 35.825-13.364 51.876 15.262 53.364 30.79"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#fff",
      d: "M150.5 89.079c1.112-2.937 1.004-5.7-.242-6.171s-3.157 1.526-4.269 4.462-1.004 5.7.242 6.171 3.157-1.526 4.269-4.462M173.31 92.825c.789-3.04.382-5.774-.907-6.109s-2.974 1.859-3.762 4.898c-.788 3.04-.382 5.775.908 6.11 1.289.333 2.973-1.86 3.761-4.899M159.425 103.985a.8.8 0 0 1-.513-.182.8.8 0 0 1-.286-.463c-.146-.661-.796-2.617-1.826-2.908s-3.05.838-3.868 1.43a.816.816 0 0 1-1.143-.183.82.82 0 0 1 .182-1.144c.323-.233 3.208-2.263 5.274-1.68 2.155.608 2.903 3.777 2.981 4.136a.82.82 0 0 1-.447.914.8.8 0 0 1-.354.08"
    }, null, -1)
  ])))
}
export default { render: render }